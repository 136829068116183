const xhr = require('xhr');

const scenarios = require('../data/scenarios');

class Death extends Phaser.Scene {

    create({scenario}) {


        console.log(scenario);
        this.add.text(400, 100, `The ${scenario.killed.name} died`, {fontSize: '25px'})
            .setAlign('center')
            .setOrigin(0.5,0.5);

        this.add.sprite(400, 180, scenario.killed.sprite)
            .setScale(1.2);

        let mostPeople = this.add.text(400, 330, '')
            .setAlign('center')
            .setOrigin(0.5,0.5);
        this.add.sprite(400, 415, 'lever-base').setScale(0.4);
        this.add.sprite(300, 425, scenario.options[0].sprite).setScale(0.6).setOrigin(0.5, 1);
        this.add.sprite(500, 425, scenario.options[1].sprite).setScale(0.6).setOrigin(0.5, 1);
        let arrow = this.add.sprite(400, 418, 'arrow').setAngle(90).setScale(0.5).setOrigin(1, 0.5);



        xhr({
            method: 'post',
            json: {scenario: scenario.index, choice: scenario.options.indexOf(scenario.killed)},
            uri: '/record'
        }, (err, res, body) => {

            let popularChoice = scenario.options[body.popularChoice];
            if (popularChoice) {
                mostPeople.setText(`Most people chose to kill the ${popularChoice.name}`);
            } else {
                mostPeople.setText(`50/50`);
            }

            this.tweens.add({
                targets: arrow,
                props: {angle: (body.choice[1]/body.total)*180},
                duration: 1000,
                ease: 'Quint.easeInOut'
            });

            this.add.text(300, 435, `${Math.round((body.choice[0]/body.total)*100)}%`).setAlign('center').setOrigin(0.5,0.5);
            this.add.text(500, 435, `${Math.round((body.choice[1]/body.total)*100)}%`).setAlign('center').setOrigin(0.5,0.5);

            this.time.delayedCall(4000, () => {
                let scIndex = scenario.index + 1;
                if (scenarios.length > scIndex) {
                    this.scene.start('Game', {scIndex});
                } else {
                    this.scene.start('End');
                }
            });
        });

    }

}

module.exports = Death;
