
let scenarios = [{
        tutorial: true,
        time: 20,
        categories: ["being"],
        options: [{
                name: "person",
                sprite: "person",
                desc: "An ordinary person.\n\nYou can't quite tell if it's\na man or a woman, but it's\ndefinitely a human.",
                score: {being: "human"}
            },
            {
                name: "cat",
                desc: "Felis catus. An ordinary\ndomestic short-haired stray.\n\nIt loves to eat fish, and\noccasionally, fish-flavoured\nbiscuits.",
                sprite: "cat",
                score: {being: "animal"}
            }
        ]
    },
    {
        title: "Black or white man?",
        categories: ["race"],
        time: 15,
        options: [{
                name: "black man",
                sprite: "black-man",
                desc: "A man of african heritage.\n\nHe enjoys a beer after work,\nlazy sunday mornings and\nprefers dogs over cats.",
                score: {race: "black"}
            },
            {
                name: "white man",
                desc: "A caucasian male.\n\nHe enjoys a beer after work,\nlazy sunday mornings and\nprefers dogs over cats.",
                sprite: "white-man",
                score: {race: "white"}
            }
        ]
    },
    {
        title: "Man or Woman?",
        categories: ["gender"],
        time: 15,
        options: [{
                name: "man",
                sprite: "white-man",
                desc: "Just some bloke. He snores\nloudly, eats quickly, and\nenjoys looking at himself in\nthe mirror a bit too much.",
                score: {gender:"male"}
            },
            {
                name: "woman",
                sprite: "girl",
                desc: "On thursdays, she can be found\njogging around the local park.\n\nWhen out partying with her\nfriends, she's usually the\nloudest one of the bunch.",
                score: {gender:"female"}
            }
        ]
    },
    {
        title: "Black cat, white cat, beli macur?",
        categories: ["gender", "race"],
        time: 15,
        options: [{
                name: "white female cat",
                sprite: "white-cat",
                desc: "A well-groomed albino persian.\n\nIt's obvious that her owner\nbrushes her every day,\nalthough this cat would look\nbeautiful regardless.",
                score: {gender:"female", race: "white"}
            },
            {
                name: "black male cat",
                sprite: "black-cat",
                desc: "The local alley cat.\n\nThis one is known for knocking\nover bins in the middle of the\nnight.",
                score: {gender:"male", race:"black"}
            }
        ]
    },
    {
        title: "Black cat, white human?",
        categories: ["being", "race"],
        time: 15,
        options: [
            {
                name: "black cat",
                sprite: "black-cat",
                desc: "This one used to work for a\nwitch, and enjoys spreading\nbad luck.",
                score: {being: "animal", race:"black"}
            },
            {
                name: "white person",
                sprite: "person",
                desc: "An androgynous-looking\ncaucasian person, originating\nfrom northern Europe.",
                score: {being:"human", race: "white"}
            }
        ]
    },
    {
        title: "A question of belief?",
        categories:["religion"],
        time: 15,
        options: [
            {
                name: "christian",
                sprite: "white-man",
                desc: "A man, distinguished by his\nbelief in the Abrahamic\nreligion of Christianity,\nworshipper of the God known as\nYahweh, and follower of his\nson Jesus Christ.",
                score: {religion: "christian"}
            },
            {
                name: "muslim",
                sprite: "muslim",
                desc: "A man, distinguished by his\nbelief in the Abrahamic\nreligion of Islam, worshipper\nof the God known as Allah, and\nfollower of his final prophet\nMuhammad.",
                score: {religion: "muslim"}
            }
        ]
    },
    {
        categories:["religion", "gender"],
        time: 15,
        options: [
            {
                name: "christian man",
                sprite: "white-man",
                desc: "He believes that the\nearth was created in six days.",
                score: {religion: "christian", gender: "male"}
            },
            {
                name: "muslim woman",
                sprite: "muslim-woman",
                desc: "A woman of the Islamic faith.\nShe wears a hijab to appear\nmodest.",
                score: {religion: "muslim", gender: "female"}
            }
        ]
    },
    {
        categories:["gender", "sexuality"],
        time: 15,
        options: [
            {
                name: "heterosexual man",
                sprite: "white-man",
                desc: "This dude hangs out in the\ngym five nights a week.",
                score: {sexuality: "heterosexual", gender: "male"}
            },
            {
                name: "lesbian woman",
                sprite: "girl",
                desc: "She enjoys daytime television.\nHer favorite show is Dr. Phil.",
                score: {sexuality: "gay", gender: "female"}
            }
        ]
    },
    {
        categories:["race"],
        time: 15,
        options: [
            {
                name: "white iPad",
                sprite: "ipad",
                desc: "A second-generation iPad.\nAvailable now in white!",
                score: {race: "white"}
            },
            {
                name: "black iPad",
                sprite: "black-ipad",
                desc: "A second-generation iPad.\nBlack is back!",
                score: {race: "black"}
            }
        ]
    },
    {
        categories:["race", "sexuality"],
        time: 15,
        options: [
            {
                name: "straight black guy",
                sprite: "black-man",
                desc: "Never wears a pair of pants\nwith fewer than six pockets.",
                score: {race: "black", sexuality: "heterosexual"}
            },
            {
                name: "gay white guy",
                sprite: "white-man",
                desc: "Moisturises daily.\nHe's a terrible dancer.",
                score: {race: "white", sexuality: "gay"}
            }
        ]
    },
    {
        categories:["religion", "being"],
        time: 15,
        options: [
            {
                name: "muslim human",
                sprite: "muslim",
                desc: "A devout worshipper.\n\nSells phone accessories on\nthe side.",
                score: {religion: "muslim", being: "human"}
            },
            {
                name: "cat",
                sprite: "cat",
                desc: "Is very cute.\n\nIt's so fluffy!",
                score: {being: "animal"}
            }
        ]
    },
    {
        categories:["race", "gender"],
        time: 15,
        options: [
            {
                name: "black woman",
                sprite: "black-woman",
                desc: "A strong independent black\nwoman who don't need no man.",
                score: {race: "black", gender: "female"}
            },
            {
                name: "white man",
                sprite: "white-man",
                desc: "A strong independent white\nman, who is quite content\non his own.",
                score: {race: "white", gender: "male"}
            }
        ]
    },
    {
        categories:["race", "religion"],
        time: 15,
        options: [
            {
                name: "black muslim",
                sprite: "black-muslim",
                desc: "Worries too much about the\ncurrent sociopolitical\nclimate.",
                score: {race: "black", religion: "muslim"}
            },
            {
                name: "white christian",
                sprite: "white-man",
                desc: "Secretly snacks on holy wafers\nat 3 A.M.",
                score: {race: "white", religion: "christian"}
            }
        ]
    },
    {
        categories:["race", "religion", "gender"],
        time: 15,
        options: [
            {
                name: "black muslim woman",
                sprite: "black-muslim-woman",
                desc: "Isn't quite sure if she's\nwearing this veil for herself,\nfor Allah, or for the men\naround her.",
                score: {race: "black", religion: "muslim", gender: "female"}
            },
            {
                name: "white christian man",
                sprite: "white-man",
                desc: "Owns two shotguns and eleven\ncrucifixes.",
                score: {race: "white", religion: "christian", gender: "male"}
            }
        ]
    },
    {
        categories:["sexuality"],
        time: 15,
        options: [
            {
                name: "heterosexual",
                sprite: "person",
                desc: "Androgynous heterosexual\nperson. They like police\nofficers, cowboys and native\nAmericans.",
                score: {sexuality: "heterosexual"}
            },
            {
                name: "homosexual",
                sprite: "person",
                desc: "Androgynous homosexual person.\nThey like construction\nworkers, soldiers and bikers.",
                score: {sexuality: "gay"}
            }
        ]
    },
    {
        categories:["being"],
        time: 10,
        options: [
            {
                name: "blind man",
                sprite: "blind-man",
                desc: "A man who cannot see anything.\nWears stylish glasses.",
                score: {being: "human"}
            },
            {
                name: "guide dog",
                sprite: "guide-dog",
                desc: "Can see much better than his\nmaster, but unfortunately\nsmells much worse.",
                score: {being: "animal"}
            }
        ]
    },
    {
        categories:["sexuality", "race"],
        time: 10,
        options: [
            {
                name: "gay duck",
                sprite: "gay-duck",
                desc: "Ever since he was a duckling,\nhe always had an unusually\nkeen fashion sense.",
                score: {sexuality: "gay"}
            },
            {
                name: "black cat",
                sprite: "black-cat",
                desc: "He blends in with the shadows.\nThey call him the catburglar,\nbut he's never been found\nguilty in court.",
                score: {race: "black"}
            }
        ]
    },

];

for (let i = 0; i < scenarios.length; i++) {
    scenarios[i].index = i;
}

module.exports = scenarios;
