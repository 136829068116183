const Phaser = require('phaser/dist/phaser.min.js');

const game = new Phaser.Game({
    width: 800, height: 450, // phone - landscape (ish)
    renderer: Phaser.AUTO,
    // pixelArt: true,
    // roundPixels: true,
    banner: false,
    parent: 'game'
});

game.scene.add('Bootstrap', require('./scenes/bootstrap'));
game.scene.add('Loading', require('./scenes/loading'));
game.scene.add('Menu', require('./scenes/menu'));
game.scene.add('Intro', require('./scenes/intro'));
game.scene.add('Game', require('./scenes/game'));
game.scene.add('Death', require('./scenes/death'));
game.scene.add('End', require('./scenes/end'));

game.scene.start('Bootstrap');
