
class Bootstrap extends Phaser.Scene {

    create() {
        this.add.text(50,50,'BOOTING');

        this.scene.start('Loading');

        let game = document.getElementById('game');
        let canvas = document.querySelector('#game canvas');



        let fscButton = document.getElementById('fsc');

        fsc.addEventListener('click', () => {
            let fsc = this.sys.game.device.fullscreen;
            fsc && fsc.request && canvas[fsc.request] && canvas[fsc.request]();
        });
    }

}

module.exports = Bootstrap;
