
const TRAIN_TWEEN_TIME = 3000;

const scenarios = require('../data/scenarios');

class Game extends Phaser.Scene {

    create({scIndex}) {

        this.scenario = scenarios[scIndex];

        this.switchIsOn = this.switchWasOn = false;
        this.switchedTimes = 0;
        this.switchIsOnFixed = null;
        this.hasTrolleyCrossedSwitch = false;

        this.soundSwitchOn = this.sound.add('switch-on');
        this.soundSwitchOff = this.sound.add('switch-off');

        let background = this.add.graphics();
        background.fillStyle(0xffffff, 1);
        background.fillRect(0, 0, 800, 450);
        background.setDepth(0);

        // create signal wire
        this.add.image(420, 135, 'signal-wire').setOrigin(0,0).setDepth(5);

        // create track
        this.track = this.add.sprite(400, 200, 'track').setDepth(10);

        // create arrow
        this.arrow = this.add.sprite(470, 157, 'arrow').setDepth(20);

        // create killables
        for (let i = 0; i < 2; i++) {
            let opt = this.scenario.options[i];
            opt.spr = this.add.sprite(120, 165 + (i*83), opt.sprite);
            opt.spr.setOrigin(1, 1).setDepth(30);
        }

        // create trolley
        this.trolley = this.add.sprite(1000, 130, 'trolley').setDepth(40);
        this.trolley.setOrigin(0.5, 1);
        this.wheel1 = {crossed: false, x: this.trolley.x - 68, y: this.trolley.y + 40};
        this.wheel2 = {crossed: false, x: this.trolley.x + 68, y: this.trolley.y + 40};
        this.trolleyVec2 = new Phaser.Math.Vector2(this.trolley.x, this.trolley.y);


        // create countdown timer
        this.countdownTimerGraphic = this.add.graphics().setDepth(50);
        this.countdownTimerGraphic.fillStyle(0xff0000);
        this.countdownTimerGraphic.fillRoundedRect(10, 10, 190, 50,10);
        this.countdownTimerText = this.add.text(15, 10, "", {color: "black", fontSize: 52, fontStyle: 'bold'}).setDepth(60);

        // create text box
        this.textBoxGraphic = this.add.graphics().setDepth(55);
        this.textBoxGraphic.fillStyle(0x0000ff);
        this.textBoxGraphic.fillRoundedRect(10, 280, 300, 160, 10);

        this.targetTextWarning = this.add.text(20, 290, 'The trolley is on course to hit:', {fontSize: '14px'}).setDepth(56);
        this.targetTextName = this.add.text(160, 320, '', {fontStyle: 'bold', fontSize: '24px'}).setDepth(56);
        this.targetTextDesc = this.add.text(20, 340, '').setDepth(56);


        // tutorial screen
        if (this.scenario.tutorial) {
            this.tutorial1 = this.add.image(0, 0, 'tutorial1').setOrigin(0,0).setDepth(70);
        }

        // create lever base
        this.leverBase = this.add.sprite(600, 410, 'lever-base').setDepth(80);
        this.leverBase.setScale(0.5);
        // create lever shaft
        this.leverShaft = this.add.graphics().setDepth(90);
        // create lever control
        this.leverControl = this.add.sprite(600, 300, 'lever-hand').setDepth(100);
        this.leverControl.setScale(0.5);
        this.leverControl.setInteractive({
            useHandCursor: true,
            draggable: true
        });
        // create lever base bolt
        this.leverBaseBolt = this.add.sprite(600, 410, 'lever-base-bolt').setDepth(110);
        this.leverBaseBolt.setScale(0.5);

        // do stuff
        this.trolleyArrivalTime = null;

        if (this.scenario.tutorial) {
            this.leverControl.once('pointerdown', this.startTutorial2, this);
        } else {
            this.startCountdown();
        }

        this.input.on('drag', (pointer, obj, x, y) => {
            if (obj !== this.leverControl) return;
            if (this.switchIsOnFixed !== null) return;
            this.updateLeverControl(x, y);
        });
        this.updateLeverControl();
        this.updateTargetText();
    }

    startTutorial2() {
        this.tutorial1.destroy();
        this.tutorial1 = null;
        this.tutorial2 = this.add.image(0, 0, 'tutorial2').setDepth(70).setOrigin(0,0);
    }

    startCountdown() {
        this.trolleyArrivalTime = Date.now() + this.scenario.time * 1000;
    }

    startTutorial3() {
        this.tutorial2.destroy();
        this.tutorial2 = null;
        this.tutorial3 = this.add.image(0, 0, 'tutorial3').setDepth(70).setOrigin(0,0);

        this.time.delayedCall(2000, () => {
            this.tutorial3.destroy();
            this.tutorial3 = null;
            this.startCountdown();
        });
    }

    updateLeverControl(pointerX, pointerY) {
        let vec2 = new Phaser.Math.Vector2(pointerX - this.leverBase.x, pointerY - this.leverBase.y);
        let ang = -vec2.angle() + (Math.PI / 2);
        if (!pointerX && !pointerY) {
            ang = Phaser.Math.DegToRad(-210);
        }

        ang = Phaser.Math.DegToRad(Phaser.Math.Clamp(Phaser.Math.RadToDeg(ang), -210, -150));

        this.leverControl.x = this.leverBase.x + Math.sin(ang) * 120;
        this.leverControl.y = this.leverBase.y + Math.cos(ang) * 120;
        this.leverControl.angle = Phaser.Math.RadToDeg(-ang) - 180;

        this.switchIsOn = Phaser.Math.RadToDeg(-ang) < 180;
        if (this.switchIsOn ^ this.switchWasOn) {

            if (this.switchIsOn) {
                this.soundSwitchOn.play();
            } else {
                this.soundSwitchOff.play();
            }

            this.updateTargetText();

            this.switchedTimes++;
            if (this.switchedTimes > 2 && this.tutorial2) {
                this.startTutorial3();
            }

            if (window.navigator.vibrate) {
                window.navigator.vibrate(100);
            }
        }
        this.switchWasOn = this.switchIsOn;

        this.leverShaft.clear();
        this.leverShaft.lineStyle(8, 0x000000, 1);
        this.leverShaft.beginPath();
        this.leverShaft.moveTo(this.leverBase.x, this.leverBase.y);
        this.leverShaft.lineTo(this.leverControl.x, this.leverControl.y);
        this.leverShaft.closePath();
        this.leverShaft.strokePath();

        if (this.switchIsOnFixed === null) {
            if (this.switchIsOn) {
                this.arrow.y = 200;
                this.arrow.angle = -30;
            } else {
                this.arrow.y = 157;
                this.arrow.angle = 0;
            }
        }
    }

    updateTargetText() {
        let target = this.scenario.options[this.switchIsOn ? 1 : 0];
        this.targetTextName.setText(target.name).setOrigin(0.5, 0.5);
        this.targetTextDesc.setText(target.desc);
    }

    update() {

        if (this.trolleyArrivalTime) {
            if (Date.now() > this.trolleyArrivalTime) {
                this.trolleyArrivalTime = null;
                this.tweens.add({
                    targets: [this.wheel1, this.wheel2],
                    props: {x: "-=1200"},
                    duration: TRAIN_TWEEN_TIME
                });
                this.countdownTimerText.setText("PANIC!");
            } else {
                this.countdownTimerText.setText(((Date.now() - this.trolleyArrivalTime) / 1000).toFixed(2));
            }
        }

        if (this.wheel1.x < 600 && !this.hasTrolleyCrossedSwitch) {
            this.hasTrolleyCrossedSwitch = true;
            this.switchIsOnFixed = this.switchIsOn;
        }

        if (!this.wheel1.crossed && this.wheel1.x < 600) {
            this.wheel1.crossed = true;

            if (this.switchIsOnFixed) {
                this.tweens.add({
                    targets: this.wheel1,
                    props: {y: '+=83'},
                    duration: TRAIN_TWEEN_TIME/6,
                    ease: 'Sine.easeInOut'
                });
            }
        }

        if (!this.wheel2.crossed && this.wheel2.x < 600) {
            this.wheel2.crossed = true;

            if (this.switchIsOnFixed) {
                this.tweens.add({
                    targets: this.wheel2,
                    props: {y: '+=83'},
                    duration: TRAIN_TWEEN_TIME/6,
                    ease: 'Sine.easeInOut'
                });
            }
        }

        let wheelAngle = Phaser.Math.Angle.BetweenPoints(this.wheel1, this.wheel2);
        this.trolleyVec2.set(this.wheel1.x - this.wheel2.x, this.wheel1.y - this.wheel2.y);
        this.trolleyVec2.scale(0.5);
        this.trolleyVec2.add(this.wheel2);
        this.trolley.x = this.trolleyVec2.x;
        this.trolley.y = this.trolleyVec2.y;
        this.trolley.angle = Phaser.Math.RadToDeg(wheelAngle);

        if (this.wheel1.x < 140) {
            this.scenario.killed = this.scenario.options[this.switchIsOnFixed ? 1 : 0];
            this.scene.start('Death', {scenario: this.scenario});
        }

    }

}

module.exports = Game;
