
const images = [
    ['locomotive', 'img/locomotive.png'],
    ['trolley', 'img/trolley.png'],
    ['btn_start', 'img/btn_start.png'],
    ['btn_fullScreen','img/btn_fullScreen.png'],
    ['lever-hand', 'img/lever-hand.png'],
    ['lever-base', 'img/lever-base.png'],
    ['lever-base-bolt', 'img/lever-base-bolt.png'],
    ['intro-track', 'img/intro-track.png'],
    ['tutorial1', 'img/tutorial1.png'],
    ['tutorial2', 'img/tutorial2.png'],
    ['tutorial3', 'img/tutorial3.png'],
    ['track', 'img/track.png'],
    ['signal-wire', 'img/signal-wire.png'],
    ['arrow', 'img/arrow.png'],
    ['cat', 'img/scenario-items/cat.png'],
    ['muslim', 'img/scenario-items/muslim.png'],
    ['black-muslim', 'img/scenario-items/black-muslim.png'],
    ['black-muslim-woman', 'img/scenario-items/black-muslim-woman.png'],
    ['muslim-woman', 'img/scenario-items/muslim-woman.png'],
    ['gay-duck', 'img/scenario-items/gay-duck.png'],
    ['black-cat', 'img/scenario-items/black-cat.png'],
    ['white-cat', 'img/scenario-items/white-cat.png'],
    ['girl', 'img/scenario-items/girl.png'],
    ['cow', 'img/scenario-items/cow.png'],
    ['person', 'img/scenario-items/person.png'],
    ['bunny', 'img/scenario-items/bunny.png'],
    ['ipad', 'img/scenario-items/ipad.png'],
    ['black-ipad', 'img/scenario-items/black-ipad.png'],
    ['black-man', 'img/scenario-items/black-man.png'],
    ['white-man', 'img/scenario-items/white-man.png'],
    ['black-woman', 'img/scenario-items/black-woman.png'],
    ['blind-man', 'img/scenario-items/blind-man.png'],
    ['guide-dog', 'img/scenario-items/guide-dog.png'],
];

const audios = [
    ['theme', 'snd/theme.mp3'],
    ['switch-on', 'snd/switch-on.mp3'],
    ['switch-off', 'snd/switch-off.mp3'],
];

class Loading extends Phaser.Scene {

    create() {
        this.add.text(50,50,'LOADING');


        for (let image of images) {
            this.load.image(...image);
        }

        for (let audio of audios) {
            this.load.audio(...audio);
        }

        this.load.on('progress', prog => {
            console.log((prog*100)  + '%');
        });

        this.load.on('complete', () => {
            console.log('Loaded');
            this.scene.start('Menu')
        });


        this.load.start();
    }

}

module.exports = Loading;
