const xhr = require('xhr');

const scenarios = require('../data/scenarios');

class End extends Phaser.Scene {

    create() {

        this.add.text(400, 50, "Game Over", {fontSize: "30px", fontStyle: 'bold'}).setOrigin(0.5, 0.5);
        this.add.text(400, 80, "What have we learned?", {fontSize: "15px"}).setOrigin(0.5, 0.5);

        this.barsToDisplay = [
            {count: 0, key: "being", scores: {animal: 0, human: 0}},
            {count: 0, key: "race", scores: {black: 0, white: 0}},
            {count: 0, key: "gender", scores: {female: 0, male: 0}},
            {count: 0, key: "religion", scores: {muslim: 0, christian: 0}},
            {count: 0, key: "sexuality", scores: {gay: 0, heterosexual: 0}}
        ].map(category => {
            let count = 0;
            for (let scenario of scenarios) {
                if (scenario.categories.includes(category.key)) {
                    count++;
                }
            }

            return {...category, count};
        });

        this.barOffset = 0;
        this.displayBars();

        this.actualVoteForUsButton = this.add.graphics();
        this.actualVoteForUsButton.fillStyle(0xff0000);
        this.actualVoteForUsButton.fillRoundedRect(295, 400, 210, 40,10);
        this.voteForUsButton = this.add.rectangle(295,400,210,40,0xff0000).setOrigin(0,0);
        this.voteForUsButton.alpha = 0.000001;
        this.voteForUsButton.setInteractive({
            useHandCursor: true,
        });
        this.voteForUsText = this.add.text(300, 405, "Vote for us!", {color: "black", fontSize: 30, fontStyle: 'bold'});
        this.voteForUsButton.on('pointerdown', this.onVoteForUsClicked, this);
    }

    onVoteForUsClicked(){
      window.location = "https://www.nodeknockout.com/entries/69-the-kipcast/vote";
    }

    displayBars() {
        this.displayBar(this.barsToDisplay.shift());
        this.barOffset += 50;

        if (this.barsToDisplay.length > 0) {
            this.time.delayedCall(1000, this.displayBars.bind(this));
        }
    }

    displayBar({key, count, scores}) {
        let gx = 200, gy = 150 + this.barOffset, gw = 400, gh = 30;

        this.add.rectangle(gx, gy, gw, gh, 0x009900).setOrigin(0, 0.5);
        this.add.rectangle(gx+2, gy, gw-4, gh-4, 0x000000).setOrigin(0, 0.5);

        let gauge = this.add.rectangle(gx, gy, 1, gh, 0x009900).setOrigin(0, 0.5);


        // add up scores for each scenario with this category (key)
        for (let scenario of scenarios) {
            if (scenario.categories.includes(key)) {
                let killed = scenario.killed;
                if (!killed) continue;
                scores[killed.score[key]]++;
            }
        }

        let mostKilled;
        for (let [key, count] of Object.entries(scores)) {
            if (!mostKilled) {
                mostKilled = {key, count};
                continue;
            }
            if (count > mostKilled.count) {
                mostKilled = {key, count};
            }
        }

        this.add.text(gauge.x, gy-30, `You hate ${mostKilled.key}s`);
        this.add.text(gauge.x + 20, gy-7, `${scores[mostKilled.key]}/${count} ${mostKilled.key}s killed`);

        if (count > 0) {
            this.tweens.add({
                targets: gauge,
                props: {displayWidth: (scores[mostKilled.key]/count) * gw},
                duration: 1500,
                ease: 'Quint.easeInOut'
            });
        }
    }

}

module.exports = End;
