
class Menu extends Phaser.Scene {

    create() {
        // this.scene.start('End');return;
        // this.scene.start('Game', {scIndex: 6});return;
        // this.scene.start('Game', {scIndex: 14});return;

        let background = this.add.graphics();
        background.fillStyle(0xffffff, 1);
        background.fillRect(0, 0, 800, 450);

        this.add.text(50,50,'The problem with trolleys', {color: 'black'})

        // create background

        // create train
        let train = this.add.container(250, 250);
        this.trolley = this.add.sprite(0, 0, 'trolley');
        train.add([
            this.add.image(200, 30, 'intro-track'),
            this.add.sprite(260, 0, 'locomotive'),
            this.trolley
        ]);
        train.angle = -20;

        this.actualStartBtnGraphic = this.add.graphics();
        this.actualStartBtnGraphic.fillStyle(0x000000);
        this.actualStartBtnGraphic.fillRoundedRect(295, 350, 210, 50,10);
        this.fakeStartBtnGraphic = this.add.rectangle(295,350,210,50,0x000000).setOrigin(0,0);
        this.fakeStartBtnGraphic.alpha = 0.000000001;
        this.fakeStartBtnGraphic.setInteractive({
            useHandCursor: true,
        });
        this.startBtnText = this.add.text(335, 355, "Start", {color: "white", fontSize: 40, fontStyle: 'bold'});
        this.fakeStartBtnGraphic.on('pointerdown', this.onStartClicked, this);

        this.add.text(400,425,'(Best with sound)', {color: 'black'}).setOrigin(0.5,0.5);
    }

    onStartClicked() {
        // scene transition
        this.time.delayedCall(3500, () => this.scene.start('Game', {scIndex: 0}));

        // remove button
        this.tweens.add({
            targets: [this.fakeStartBtnGraphic, this.startBtnText, this.actualStartBtnGraphic],
            props: {alpha: 0},
            duration: 500,
            ease: 'Cubic.easeIn'
        });

        // snap trolley link
        this.tweens.add({
            targets: this.trolley,
            props: {x: -500},
            ease: 'Quint.easeIn',
            duration: 3000
        });

        this.sound.play('theme', {loop: true});
    }

}

module.exports = Menu;
